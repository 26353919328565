<template>
    <div class='forms'>
        <ul class="forms_items">
            <li class="forms_items_item" 
                v-for="(item, index) in formItems"
                :key="index">
                <label>{{item.label}}</label>
                <div class="item_content">
                    <p v-if="item.type == 'read'">{{item.value}}</p>
                    <p 
                        v-if="item.type == 'button'" 
                        @click.stop="handleEvent(item.eventName)"
                         >
                        <!-- {{item.value}} -->
                        <span :class="item.tipColor ? item.tipColor : item.value ? '' : 'item_tip'">
                            {{item.value ? item.value : item.tip}}
                        </span>
                        <span class="item_arrow"></span>
                    </p>
                    <input 
                        v-if="item.type == 'input'" 
                        v-model="item.value" 
                        :type="item.textType || 'text'"
                        :placeholder="item.tip ? item.tip :'请输入'+item.label"/>
                    <button 
                        class="item_content_input_button" 
                        :class="'button_' + item.buttonColor" 
                        @click.stop="handleEvent(item.eventName)"
                        v-if="item.eventName">{{item.eventLabel}}</button>
                </div>
            </li>
        </ul>
        <div class="forms_tip" v-if="hasTip">
            <ul v-if="isArray(tip)">
                <li v-for="(item, index) in tip" :key="index">
                    <p>{{index+1}}、{{item}}</p>
                </li>
            </ul>
            <p v-else>
                {{tip}}
            </p>
        </div>
        <!-- <slot>
            <p class="">{{tip}}</p>
        </slot> -->
        <div @click="handleEvent(button.eventName)" class="forms_button" v-if="button.label.length > 0">
            <button >{{button.label}}</button>
        </div>
    </div>
</template>
<script lang='ts'>
import { computed, reactive, toRefs } from 'vue'
import { isArray } from '../utils/util'
export default {
    name: '',
    props: {
        formItems: {
            type: Array,
            default: () => {
                return [
                    {label: '选择银行', value: '', tip: '', showArrow: true, type: 'button'},
                    {label: '银行所在地', value: '', tip: '', showArrow: true, type: 'button'},
                    {label: '支行名称', value: '', type: 'input'},
                    {label: '真实姓名', value: '', type: 'input'},
                    {label: '银行卡号', value: '', type: 'input'}
                ]
            }
        },
        button: {
            type: Object,
            default: () => {
                return {
                    label: '确定',
                    eventName: 'submit'
                }
            }
        },
        tip: {
            type: String || Array,
            default: '' || []
        },
    },
    setup(props, context) {
        const state= reactive({
        })
        function handleEvent(eventName, index) {
            if (eventName) {
                context.emit(eventName, index)
            }
        }
        const hasTip = computed(() => {
            return props.tip.length > 0
        })
        return {
            ...toRefs(state),
            handleEvent,
            isArray,
            hasTip
        }
    },
}
</script>
<style lang='scss' scoped>
.item_tip {
        color: $gray-light;
    }
.forms {
    &_tip {
        font-size: $font-small;
        color: $gray-light;
        padding: 0 $margin-big;
        text-align: left;
    }
    
    &_items{
        background: #fff;
        margin: $margin-big 0;
        &_item {
            padding: 15px 0;
            margin: 0 20px;
            display: flex;
            align-items: center;
            position: relative;
            &::after {
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                content: '';
                height: 1px;
                background: $line-gray;
                transform: scaleY(-50%);
            }
            label {
                padding-right: 10px;
                width: 80px;
                text-align: left;
            }
            .item_content {
                &_input_button {
                    position: absolute;
                    // width: 120px;
                    right: 3px;
                    top: 50%;
                    transform: translateY(-50%);
                    border-radius: $box-radius-small;
                }
                .button_red {
                    color: $theme-dark;
                    border: solid 1px;
                    padding: 0px 10px;
                }
                .button_gray {
                    background: $bg-gray;
                    color: $gray-light;
                    padding: 5px 10px;
                }
                p {
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                }
            }
        }
        .item_arrow {
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            display: inline-block;
            width: 30px;
            height: 15px;
            background: url('../assets/imgs/arrow_right_gray.png') center center no-repeat;
            background-size: contain;
        }
    }
    &_button {
        text-align: center;
        background: $theme-dark;
        color: #fff;
        padding: 10px 0;
        margin: $margin-big;
        margin-top: 40px;
        border-radius: $box-radius-small;
        font-size: $font-big;
    }
}
</style>