<template>
    <div class='withdraw'>
        <Form 
            :formItems="formItems" 
            :tip="tips"
            @all="all" 
            @submit="submit" 
            @chooseBank="chooseBank">
            </Form>
         <!-- 充值密码弹出框 -->
        <dialog-box :showDialog="showDialog" @close="close">
            <div class="password">
                <h4>请输入提现密码</h4>
                <ul class="input_items">
                    <li 
                        class="input_items_item"
                        v-for="(item, index) in inputValues" 
                        :key="index">
                            <input @input="inputEvent(index)" v-model="item.value" :ref="refs[index]"/>
                        </li>
                </ul>
            </div>
        </dialog-box>
        <!-- 选择弹出框 -->
        <van-popup v-model:show="show" round position="bottom">
            <van-cascader
                v-model="cascaderValue"
                title="请选择/添加银行卡"
                placeholder="请选择或添加新银行卡"
                :options="bankOptions"
                @close="show = false"
                @change="onChange($event)"
                />
        </van-popup>
    </div>
</template>
<script>
import { reactive, toRefs, ref } from 'vue'
import Form from '../../components/form.vue'
import { withdrawInfo, withdraw, userBankCards, updateDeposit} from '../../utils/api'
import { jumpTo, toast, inputIsAllFill, sleep, getUserInfo } from '../../utils/util'
import { Cascader, Popup} from 'vant'
export default {
    name: '',
    components: { Form, 'van-cascader': Cascader, 'van-popup': Popup },
    setup() {
         const state= reactive({
             formItems: [
                 {label: '收款人姓名', value: '', type: 'input'},
                 {label: '可提现余额', value: '0', type: 'read'},
                 {label: '提现金额', value: '', type: 'input', eventName: 'all', eventLabel: '全部', buttonColor: 'red'},
                 {label: '选择银行', value: '', eventName: 'chooseBank', type: 'button', tip: '', showArrow: true},
                 {label: '提现密码', value: '', type: 'input', textType: 'password'}
             ],
             inputValues: [
                 {value: '', ref: ''},
                 {value: '', ref: ''},
                 {value: '', ref: ''},
                 {value: '', ref: ''},
                 {value: '', ref: ''},
                 {value: '', ref: ''}],
            showDialog: false,
            moneyCount: 0,
            show: false,
            bankOptions: [{value: 'add', text: '添加银行卡'}],
            bankId: '',
            cascaderValue: '',
            time: 3,
            tips: [
                '仅限中奖奖金，可进行提现，单笔提现金额需≥5元；',
                '单笔最高提现金额20000元整；',
                '提现申请每日仅限一次，到账时间约1-3工作日。'
            ],
            buttonActive: false
        })
        let refs = []
        state.inputValues.forEach(() => {
            let refValue = ref()
            refs.push(refValue)
        })
        async function loadData() {
            try {
                const result = await withdrawInfo()
                const userInfo = await getUserInfo()
                if (result.code == 0) {
                    const data = result.data
                    const account = data.account
                    if (data.account && data.account.realName) {
                        state.formItems[0].value = data.account.realName
                        state.formItems[0].type = 'read'
                    } else {
                        toast('信息未完善, 即将跳转到完善信息页面...')
                        await sleep(2000)
                        jumpTo(`/fillInfo?name=${account.realName}&IdCard=${userInfo.cardNumber}`)
                    }
                    state.moneyCount = data.canMoney/100
                    state.formItems[1].value = state.moneyCount + '元'
                    if (!result.data.canWithdraw) {
                        toast('不支持提现')
                    } else{
                        if (!account.password) {
                            state.showDialog = true
                            await sleep(10)
                            refs[0].value.focus()
                        } else {
                            state.showDialog = false
                        }
                    }
                }
            } catch(e) {
                console.error(e)
            }
        }
        // 判断是否为整数
        function isInteger(obj) {
            return obj%1 === 0
        }
        loadData()
        // 获取后四位
        function getLastFour(num) {
            return num.substr(num.length > 3 ? num.length - 4 : 0)
        }
        // 获取存管银行
        async function getBanks() {
            try {
                const result = await userBankCards()
                if(result.code == 0) {
                    result.data.bindAccouts.forEach(ele => {
                        state.bankOptions.push({value: ele.name, text: ele.name+'('+getLastFour(ele.number)+')', id: ele.id})
                    })
                }
            } catch(e) {
                console.error(e)
            }
        }
        getBanks()
        async function submit() {
            try {
                const items = state.formItems
                if (!inputIsAllFill(items)) {
                    // toast('填写完整才能提交')
                    return
                }
                if (!isInteger(items[2].value)) {
                    toast('提现金额不能有小数')
                    return
                }
                if (state.buttonActive) {
                    return
                }
                state.buttonActive = true
                const params = {
                    realName: items[0].value,
                    money: items[2].value,
                    id: state.bankId,
                    password: items[4].value,
                }
                const result = await withdraw(params)
                if (result.code == 0) {
                    // toast('提现成功')
                    jumpTo('/withdrawSuccess')
                }
                state.buttonActive = false
            } catch(e) {
                state.buttonActive = false
                console.error(e)
            }
        }
        function all() {
            state.formItems[2].value = state.moneyCount
        }
        function close() {
            // state.showDialog = false
            toast('提现前需要设置提现密码')
        }
        function chooseBank() {
            state.show = true
        }
        function onChange(event) {
            console.log(event)
            state.bankId = event.selectedOptions[0].id
            if (state.cascaderValue == 'add'){
                jumpTo('/addBankCard')
            } else {
                state.formItems[3].value = state.cascaderValue
                state.show = false
            }
        }
        async function inputEvent(index) {
            const item = state.inputValues[index]
            console.log(item.value)
            if (isNaN(item.value)) {
                toast('只能输入数字')
                item.value = ''
            } else {
                if (index < state.inputValues.length-1) {
                    refs[index+1].value.focus()
                } else {
                    refs[index].value.blur()
                    let num = ''
                    state.inputValues.forEach(ele => {
                        num += ele.value
                    })
                    const result = await updateDeposit({newPwd: num})
                    if (result.code == 0) {
                        toast('提现密码设置成功')
                        state.showDialog = false
                    }
                }
            }
        }
        return {
            ...toRefs(state),
            close,
            submit,
            chooseBank,
            onChange,
            all,
            refs,
            inputEvent
        }
    },
}
</script>
<style lang='scss' scoped>
.withdraw {
    :deep .van-tabs__wrap {
        display: none;
    }
    :deep .van-cascader__options {
        margin: 0 10px;
    }
    :deep .van-cascader__option {
        border-bottom: dashed #efefef 1px;
        font-size: $font-big;
        padding: 15px 0;
        span {
            font-size: $font-middle;
            padding: 5px 0;
        }
    }
    text-align: center;
    .input_items {
        display: flex;
        justify-content: space-between;
        padding: 25px 10px;
        &_item {
            border: solid $line-gray 1px;
            width: 33px;
            height: 33px;
            input {
                display: inline-block;
                width: 100%;
                line-height: 33px;
                text-align: center;
            }
        }
    }
}
</style>